export default {
  PAYMENTS_LIST: "Liste des paiements",
  ADD_PAYMENT: "Ajouter un paiement",
  PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_PAYMENT: "Modifier le paiement",
  PAYMENT_SOURCE_MANUAL: "Manuel",
  PAYMENT_SOURCE_PAYPAL: "Paypal",
  PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  AWAITING_DPA_CONTRACT_SIGNATURE: "En attente de Signature",
  DPA_CONTRACT_SIGNATURE_DECLINED: "Signature refusée",
  DRAFT: "Brouillon",
  COMPLETED: "Completé",
  CANCELED: "Annulé",
  PENDING: "En cours",
  MANUAL: "Manuel",
  STRIPE: "Stripe",
  PAYPAL: "Paypal",
  CASH: "Cash",
  DPA: "DPA",
};
